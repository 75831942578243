<template>
    <div>
        <breadcrumb
            name="Car Models"
            title="Car Model Details"
            subtitle="Add Adds On"
            @onTitleClicked="goBack"
        />

        <div class="body">
            <choose-model
                @onModelChosen="modelChosen"
                :selectedModelID="$route.params.id"
            />

            <div>
                <br />
                <vs-row>
                    <vs-col vs-lg="6" vs-sm="12">
                        <div class="drop-down">
                            <label for="addson">Choose Adds On</label>
                            <select
                                id="addson"
                                v-model="selected_adonid"
                                style="height: 40px"
                            >
                                <option value="0" selected disabled>
                                    Choose Adds On
                                </option>
                                <option
                                    v-for="(adds, index) in addson"
                                    :value="adds.id"
                                    :key="index"
                                >
                                    {{ adds.name_en }}
                                </option>
                            </select>
                        </div>
                    </vs-col>

                    <vs-col vs-lg="6" vs-sm="12">
                        <!-- Price -->
                        <vs-input
                            label="Price"
                            v-model="price"
                            size="medium"
                            class="input"
                            danger-text="This field is mandatory"
                        />
                    </vs-col>

                    <vs-col vs-lg="6" vs-sm="12">
                        <div class="optionBtns">
                            <br />
                            <vs-button
                                :disabled="invalidForm"
                                class="mr-5 save"
                                icon-pack="feather"
                                icon="icon-save"
                                @click="confirmSubmitData"
                                >Save
                            </vs-button>
                            <vs-button
                                class="ml-5 cancel"
                                type="border"
                                color="primary"
                                @click="goBack"
                            >
                                Cancel
                            </vs-button>
                        </div>
                    </vs-col>
                </vs-row>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "../../../../components/general/breadcrumb";
import ChooseModel from "../../../../components/custom/choose-model";
import Router from "vue-router";

export default {
    name: "add-addOns",
    components: { ChooseModel, Breadcrumb, Router },
    data() {
        return {
            addson: [],
            modelID: "0",
            selected_adonid: "0",
            price: "",
        };
    },
    computed: {
        invalidForm() {
            if (
                this.price === "" ||
                this.selected_adonid === "0" ||
                this.modelID === "0"
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        goBack() {
            this.$router.push("/models/" + this.$route.params.id);
        },
        modelChosen(id) {
            this.modelID = id;
        },

        confirmSubmitData() {
            this.$vs.dialog({
                type: "confirm",
                accept: () => this.saveData(),
                color: "primary",
                title: "Save Add-Ons",
                text: "You are going to save this data",
            });
        },

        saveData() {
            let payload = {
                model_id: this.modelID,
                adon_id: this.selected_adonid,
                price: this.price,
            };

            this.$http
                .post("/adons/add-adon-to-model", payload)
                .then(() => {
                    this.$vs.loading.close();
                    //console.log(r);
                    this.$vs.notify({
                        title: "Saved",
                        text: "Data is Saved Successfully",
                        color: "primary",
                    });
                    this.goBack();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger",
                    });
                });
        },
        getAllAddsOn() {
            this.$vs.loading();
            this.$http
                .get("/adons")
                .then((res) => {
                    const total = res.data.data.total;
                    this.$http
                        .get(`/adons?per_page=${total}`)
                        .then((res) => {
                            this.addson = res.data.data.data;
                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            //console.log({e});
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Something Error",
                                text: "Couldn't fetch adds on from the server",
                                color: "danger",
                            });
                        });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch adds on from the server",
                        color: "danger",
                    });
                });
        },
    },
    mounted() {
        this.modelID = this.$route.params.id;
        this.getAllAddsOn();
    },
};
</script>

<style scoped lang="scss">
@import "src/views/basic-styles/mixins";

.flex {
    justify-content: flex-end;
    padding-right: 75px;
}

.body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.drop-down {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
        font-size: 1rem;
        padding: 5px;
    }

    select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #00000030;
    }
}

.input {
    width: 90%;
    margin-top: 10px;
}

.optionBtns {
    width: 186%;
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .save {
        position: relative;
        left: 25px;
    }
}
</style>

